:root {
    --white: #FBFBFD;
    --dark-blue: #151232;
    --hover-blue: #151232aa;
    --outline-blue: #150f4d8a;
    --bg: #D9D9D9;
}

form {
    display: flex;
    flex-wrap: wrap;
    margin: -6px;
    justify-content: flex-end;
}

input, textarea {
    background-color: var(--bg);
    border-radius: 5px;
    border: none;
    width: -webkit-fill-available;
    min-height: 50px;
    margin: 6px;
    padding: 13px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    resize: none;
}

input:focus-visible, textarea:focus-visible {
    outline-color: var(--outline-blue);
}

textarea {
    line-height: 150%;
}

.email-body {
    min-width: calc(100vw - 6rem);
}

.submit {
    width: auto !important;
    background-color: var(--dark-blue);
    color: var(--white);
    font-size: 18px;
    text-transform: uppercase;
    padding: 0rem 1.5rem;
}

@media (width < 500px) {
    form div, div form {
        display: flex;
        flex-direction: column !important;
    }
}
