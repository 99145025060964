.App {
  text-align: center;
}

.transition-all {
  transition: all 0.1s linear;
}

.borders {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(245 245 245 / 1) !important;
  border-bottom-width: 1px !important;
  border-bottom: solid;
}

.navbar {
  width: calc(100% - 337px) !important;
  height: 4rem !important;
  display: flex !important;
  z-index: 80 !important;
  position: fixed !important;
  right: 0;
  padding: 0 24px 0 12px;
  color: #000000d9;
  line-height: 64px;
  background: #FFFFFF;
  /* box-shadow: 0px 4px 4px 0px #7B7B7B24; */
}

.root-content{
  display: flex;
  flex-direction: row;
  padding-top: 64px;
}

.sidebar {
  flex: 0 0 300px;
  max-width: 300px;
  min-width: 300px;
  width: 300px;
  min-height: 100svh !important;
  left: 0 !important;
  position: fixed;
  min-width: 0;
  background: #FFFFFF;
  transition: all .2s;
  z-index: 70;
  top: 0;
  box-shadow: 0px 4px 4px 0px #7B7B7B24;
  border: 1px solid #e5e7eb;
}

.sidebar-nav {
  display: flex;
  justify-content: center;
}

a {
  user-select: none;
}

h1 {
  font-family: 'Bayon', sans-serif;
  font-weight: 300;
  font-size: 30px;
  color: #141132;
}

.sidebar-header-button {
  margin: 0.75rem 5px;
  padding: 0px 0.75rem;
}

.sidebar-header-button:hover {
  background-color: #E5E5E555;  /* #a5a5a115*/
  cursor: pointer;
}

.selected-tab {
  background-color: #E5E5E5;
}

.selected-tab-underline {
  background-color: #C8C9F4;
  /* width: 90px; */
  height: 8px;
  margin-top: -21px;
  /* top: 83px;
  position: fixed; */
}

.content {
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
  background-color: #0f000009;
  flex: auto;
  margin-left: 300px;
  height: calc(100vh - 64px);
}

.item {
  background: #141132;
  /* margin: 16px; */
  padding: 0.5rem;
  box-shadow: 0px 4px 4px 0px #7B7B7B24;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  text-wrap: nowrap;
}

.timeline-line {
  /* width: 32px; */
  background: #151132;
  height: 3px;
  align-self: center;
}

/* h1 {
  background-color: transparent;
  color: #C8C9F4;
  font-family: 'Bayon', sans-serif;
  font-weight: 300;
  font-size: 32px;
  padding: 0px 0.25rem;
  margin: 0px 0.5rem;
} */

h2 {
  background-color: transparent;
  color: #C8C9F4;
  font-family: 'Bayon', sans-serif;
  font-weight: 300;
  font-size: 24px;
  padding: 0px 0.25rem;
  margin: 0px 0.5rem;
}

h3 {
  background-color: transparent;
  color: #ffffffc0;
  font-family: 'Bayon', sans-serif;
  font-weight: 300;
  font-size: 16px;
  padding: 0px 0.25rem;
  margin: 1px 0.25rem 0px;
}

h4 {
  background-color: transparent;
  color: #000000;
  font-family: 'Bayon', sans-serif;
  font-weight: 300;
  font-size: 24px;
  padding: 0px 0.25rem;
  margin: 1px 0.75rem 0px;
  text-align: left;
}

h5 {
  background-color: transparent;
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  padding: 0px 0.25rem;
  margin: 1px 0.0rem 0px;
  text-align: left;
  line-height: 170%;
}

body {
  background-color: #00000000 !important;
}

.radio {
  display: flex;
  margin: 0px 0px 0.25px 1rem;
  align-items: center;
}

.disabled {
  user-select: none;
  -webkit-user-select: none; /*Safari*/
  -moz-user-select: none; /*Firefox*/
}

/* h5::before {
  margin: 4rem;
} */

.thread-title {
  position: relative;
  text-align: left;
  color: #000000d9;
  margin-left: -94px;
  margin-top: -98px;
  text-wrap: nowrap;
}
/* .date {
  background-color: transparent;
  color: #ffffffc0;
  font-family: 'Bayon', sans-serif;
  font-weight: 300;
  font-size: 14px;
  padding: 0.75rem;
  margin: 0.5rem;
} */

.style1 {
  display: flex;
  padding: 100px;
  margin: 1rem;
  background-color: #DCDAE7;
  border: 1px solid #000000d9;
}

.style2 {
  display: contents;
}



.board {
  display: grid;
  gap: 1px;
  background-color: #00000000;
  padding: 10px;
  /* background-image:
    repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%),
    repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%);
    background-size: 71px 71px; */
}

.board-event {
  background-color: #141132;
  text-align: center;
  /* padding: 20px; */
  font-size: 30px;
  padding: 0.5rem;
  border-radius: 8px;
}

.item1 {
  grid-column: 1000 / span 4;
  grid-row: 1;
}

.item2 {
  grid-column: 100 / span 100;
  grid-row: 2 ;
}

.item5 {
  grid-column: 1 / span 3;
  grid-row: 3;
}