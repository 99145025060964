:root {
    --white: #FBFBFD;
    --dark-blue: #050325;
    --hover-blue: #151232aa;
    --outline-blue: #150f4d8a;
    --bg: #D9D9D9;
}

.container {
    background-color: var(--dark-blue);
    display: flex;
    flex-direction: column;
}

.footer-link-section {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 2rem;
    flex-wrap: wrap;
}

.footer-column {
    display: flex;
    flex-direction: column;
    text-align: left;
    min-width: 200px;
}

.footer-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0px;
}

h2 {
    color: #FFFFFF;
    margin: 0.25rem 0rem;
    font-size: 2rem;
}

.footer-link {
    color: var(--bg);
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    text-transform: none;
    margin: 4px;
    padding: 0px;
    transition: all .2s ease;
}

.footer-link:hover {
    color: var(--white)
}

.footer-icon {
    color: var(--bg);
    margin: 8px 1px;
}

.footer-icon:hover {
    color: var(--white)
}

.footer-rights {
    color: #FFFFFF75;
    text-transform: uppercase;
    margin-bottom: 2rem;
}