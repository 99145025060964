:root {
    --white: #FBFBFD;
    --dark-blue: #151232;
    --hover-blue: #151232aa;
}

h1 {
    text-wrap: nowrap;
    /* color: var(--dark-blue); */
}

a {
    font-family: 'Bayon', sans-serif;
    text-decoration: none;
    color: var(--dark-blue);
    font-weight: 300;
    font-size: 24px;
    padding: 0px 0.25rem;
    margin: 0px 0.5rem;
    text-wrap: nowrap;
}

ul a {
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
    color: var(--dark-blue);
    font-weight: 500;
    font-size: 16px;
    padding: 0rem;
    margin: 0px;
    display: block;
    text-wrap: nowrap;
}

a:hover {
    color: var(--hover-blue);
}

.nav {
    background-color: var(--white);

}

.nav-wrapper {
    display: flex;
    align-items: right;
    width: -webkit-fill-available;
    justify-content: flex-end;
}

.remove-margin {
    margin: 0px !important;
}

.menu {
    position: absolute;
    background-color: var(--white);
    color: var(--dark-blue);
    text-align: left;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    box-shadow: 0px 1px 11px 0px #00000029;
    min-width: 250px;
    transition: all 0.3s ease;
    z-index: 1;
    margin: 0;
}

.menu-on {
    transform: translate(-50%, 73px);
    opacity: 1;
    visibility: visible;
}

.menu-off {
    transform: translate(-50%, 53px);
    opacity: 0;
    visibility: hidden;
}


ul, li {
    list-style-type: none;  
    padding: 0.5rem;
    margin: 0px
}

li:hover {
     background-color: var(--bg);
     cursor: pointer;
}