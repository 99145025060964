:root {
    text-align: center;
  }

a {
    margin: 20px;
}

.wrap {
    text-wrap: wrap;
}

.banner {
    max-width: calc(100vw - 2rem);
}

@media (width > 650px) {
    .banner {
        max-width: 50vw;
    }
}

.section-1 {
    display: flex;
    width: 100vw;
    margin-top: 3rem;
    flex-direction: row-reverse;
    justify-content: center;
}

.section-1a {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    flex-wrap: nowrap;
    max-width: calc(50vw - 2em);
    padding-right: 63px;
    padding-left: 1em;
}

@media (width > 1000px) {
    .section-1 {
        flex-wrap: wrap;
    }
    .section-1a {
        max-width: calc(50vw - 63px);
        padding-left: 1em;
    }
}

@media (width < 650px) {
    .section-1 {
        flex-wrap: wrap;
    }
    .section-1a {
        max-width: calc(100vw - 63px);
        padding-left: 0em;
    }
}

@media (width < 775px) {
    h1 {
        font-size: 24px !important;
    }

    body {
        font-size: 15px !important;
    }

    button {
        max-width: 150px !important;
        height: 54px !important;
    }
    .who-we-are {
        flex-direction: column !important;
        height: auto !important;
        padding: 8rem 0 !important
    }
    .who-we-are-blurb {
        max-width: calc(100vw - 96px) !important;
    }
    .h0 {
        text-align: center !important;
        padding: 2rem 0 3rem 0 !important;
    }
}

.h0 {
    font-size: 3em;
    font-family: 'Bayon', sans-serif;
    text-align: right;
    color: rgba(0, 0, 0, 0.7);
}

.span-highlight {
    color: black;
}

.who-we-are {
    width: 100vw;
    height: 100vh;
    margin-top: 8rem;
    padding: 8rem 10rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    padding: 2rem;
}

.who-we-are-blurb {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    flex-wrap: nowrap;
    max-width: 50vw;
}

.flex-row {
    display: flex;
}

button {
    height: 48px;
    background-color: #141132;
    color: #ffffff;
    border-radius: 5px;
    text-decoration: none;
    border: none;
    padding: 0 2rem;
    margin: 0 1rem 0rem 0rem;
}

.button-link {
    margin: 5px 0 0 0;
    padding: 0;
}

.button-text {
    color:#ffffff ;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    user-select: none;
}

.button-link-outlined {
    margin: 5px 0 0 0 !important;
    padding: 0 !important
}

.button-text-outlined {
    background-color: transparent !important;
    color: #141132;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    border: 2px solid #141132;
    border-radius: 5px;
    user-select: none;
}

button:hover {
    color:#C8C9F4 ;
    cursor: pointer;
}

.span-text { 
    max-width: 350px;
}

.span-tags {
    font-size: 18px;
    margin: 0px;
    padding: 0px;
}

.section-full {
    width: 100vw;
    padding: 3rem;
    text-align: left;
}

.section-full-inner {
    /* text-align: left; */
    text-wrap: wrap;
}
